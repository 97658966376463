export const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const sleep = (timeout = 5000): Promise<void> => {
  return new Promise((r) => setTimeout(() => r(), timeout));
};

type Listener = (hovering: boolean) => any;

export const mouseHoverObserver = (e: HTMLElement | Element) => {
  let isHovering = false;
  let listener: Listener;

  e.addEventListener("mouseenter", () => {
    if (!isHovering && listener) {
      isHovering = true;
      listener(isHovering);
    }
  });

  e.addEventListener("mouseleave", () => {
    if (isHovering && listener) {
      isHovering = false;
      listener(isHovering);
    }
  });

  return {
    onHover: (cb: Listener) => {
      if (typeof cb === "function") {
        listener = cb;
      }
    },
  };
};

export const debounce = <T>(cb: (...args: T[]) => void, timeout = 1000) => {
  let timeoutID: NodeJS.Timeout;

  return (...args: T[]) => {
    clearTimeout(timeoutID);

    timeoutID = setTimeout(() => {
      cb(...args);
    }, timeout);
  };
};

export const renderCapcthaMessage = (
  capcthaContainer: HTMLElement,
  message = "Please solve the captcha below."
) => {
  capcthaContainer.querySelector("span")?.remove();
  const msg = document.createElement("span");
  msg.classList.add("c-primary", "text-lg");
  msg.style.display = "block";
  msg.style.margin = "10px 0";
  msg.textContent = message;
  capcthaContainer.insertAdjacentElement("afterbegin", msg);
};

export const formFlashMessage = (
  container: HTMLFormElement,
  targetEl: HTMLElement
) => {
  let _hasMessage = false;
  const removeMessage = () => {
    if (_hasMessage) {
      container.querySelector(".form-message")?.remove();
      _hasMessage = false;
    }
  };

  const flashMessage = (success: boolean, message: string) => {
    if (_hasMessage) {
      removeMessage();
    }

    const msg = document.createElement("div");
    msg.style.color = success ? "#267d26" : "#e80000";
    msg.style.display = "block";
    msg.style.margin = "20px 0";
    msg.textContent = `${success ? "✔️" : "❌"} ${message}`;
    msg.classList.add("form-message");
    _hasMessage = true;
    targetEl.insertAdjacentElement("beforebegin", msg);
  };

  return {
    get hasMessage() {
      return _hasMessage;
    },
    remove: removeMessage,
    flash: flashMessage,
  };
};

let loadedScript = false;
export const lazyLoadCaptcha = (): Promise<void> => {
  return new Promise((resolve) => {
    if (loadedScript) {
      resolve();
      return;
    }

    const scripTag = document.createElement("script");
    scripTag.src = CAPTCHA_SCRIPT_SRC;
    document.head.appendChild(scripTag);

    scripTag.onload = () => {
      grecaptcha.ready(() => {
        loadedScript = true;
        resolve();
      });
    };
  });
};
