import {
  emailRegex,
  formFlashMessage,
  lazyLoadCaptcha,
  renderCapcthaMessage,
} from "./utils";

if (__DEV__) {
  if (module.hot) {
    module.hot.accept();
  }
}

(window as any).$ = document.querySelectorAll.bind(document);

// Mobile Nav
const mobileNavToggle = $("#mobile-nav-toggle")[0] as HTMLButtonElement;
const navContainer = $(".cf-header .nav")[0] as HTMLElement;
const navContainerComputedStyles = getComputedStyle(navContainer);
const mobileBreakPoint = +navContainerComputedStyles
  .getPropertyValue("--mobile-breakpoint")
  .replace("px", "");
let navToggleLocked = false;
let navOpen = false;

mobileNavToggle.addEventListener("click", (e: Event) => {
  if (navToggleLocked) return;

  let t = e.target as HTMLElement;

  if (t.tagName === "SPAN") t = t.parentElement!;

  if (t.classList.contains("active")) {
    // close toggle
    mobileNavToggle.classList.remove("active");
    // close container
    navContainer.classList.add("nav-not-visible");
    document.body.classList.remove("no-overflow");
    navToggleLocked = true;
    setTimeout(() => {
      navContainer.classList.remove("nav-is-visible");
      navToggleLocked = false;
    }, 1000);
    navOpen = false;
  } else {
    // open toggle
    mobileNavToggle.classList.add("active");
    document.body.classList.add("no-overflow");
    // open container
    navContainer.classList.remove("nav-not-visible");
    navContainer.classList.add("nav-is-visible");
    document.body.classList.add("no-overflow");
    navOpen = true;
  }
});

const mq = matchMedia(`(min-width: ${mobileBreakPoint}px)`);

window.addEventListener("resize", () => {
  if (mq.matches && document.body.classList.contains("no-overflow")) {
    document.body.classList.remove("no-overflow");

    if (navOpen) {
      mobileNavToggle.click();
    }
  } else {
    if (
      window.innerWidth <= mobileBreakPoint &&
      !document.body.classList.contains("no-overflow") &&
      navOpen
    ) {
      document.body.classList.add("no-overflow");
    }
  }
});

// ./ Mobile Nav //

if ($("#fyear")[0]) {
  ($("#fyear")[0] as HTMLSpanElement).textContent = new Date()
    .getFullYear()
    .toString();
}

const observer = new IntersectionObserver(
  (entries) => {
    for (const entry of entries) {
      const t = entry.target as HTMLElement;
      const animatedClass = t.dataset.animation ?? "";

      if (entry.isIntersecting) {
        t.classList.add(...animatedClass.split(" "), "on-animation");
        observer.unobserve(entry.target);
      }
    }
  },
  {
    root: null,
    rootMargin: "0px",
    threshold: 0.2,
  }
);

$(".animated").forEach((e) => observer.observe(e));

const newsLetterInitialFormQuery = FORM_SETTINGS.newsletter?.query || {};
let newsLetterInitialFormQueryString = "";

if (Object.keys(newsLetterInitialFormQuery).length) {
  newsLetterInitialFormQueryString = new URLSearchParams(
    newsLetterInitialFormQuery
  ).toString();
}

const newsLetterFormURL = FORM_SETTINGS.newsletter.action;

$(".news-letter-form").forEach((e) => {
  const newsLetterForm = e as HTMLFormElement;
  const newsLetterEmailInput = newsLetterForm.querySelector(
    "input[type=email]"
  )! as HTMLInputElement;
  const submitBtn = newsLetterForm.querySelector(
    "button[type=submit]"
  )! as HTMLButtonElement;
  const capcthaContainer = newsLetterForm.querySelector(
    ".captcha-container"
  )! as HTMLDivElement;
  const submitColors = { active: "#00a1ff", default: "#66c7ff" };

  let emailValid = false;
  let captchaInitialized = false;
  let captchaWidgetID: number;

  const flashMessage = formFlashMessage(newsLetterForm, capcthaContainer);

  let solveMsgTID: NodeJS.Timeout;
  newsLetterEmailInput.addEventListener("input", (e) => {
    const inpt = e.target as HTMLInputElement;

    flashMessage.remove();

    const value = inpt.value;
    emailValid = emailRegex.test(value);
    submitBtn.style.background =
      submitColors[emailValid ? "active" : "default"];
    submitBtn.disabled = !emailValid;
  });

  newsLetterForm.addEventListener("submit", async (e) => {
    e.preventDefault();
    const t = e.target! as HTMLFormElement;

    if (!captchaInitialized) {
      await lazyLoadCaptcha();
      captchaWidgetID = grecaptcha.render(capcthaContainer, {
        sitekey: CAPTCHA_SITE_KEY,
      });
      renderCapcthaMessage(capcthaContainer);
      captchaInitialized = true;
      return;
    }

    const emailValue = newsLetterEmailInput.value;
    const captchaResponse = grecaptcha.getResponse(captchaWidgetID);

    if (!captchaResponse) {
      clearTimeout(solveMsgTID);
      flashMessage.flash(false, "Please solve the captcha first.");
      solveMsgTID = setTimeout(() => {
        flashMessage.remove();
      }, 5000);
      submitBtn.disabled = false;
      return;
    }

    fetch(`${newsLetterFormURL}?email=${emailValue}`, {
      method: FORM_SETTINGS.newsletter.method,
      headers: {
       'Content-Type': 'application/json'
     },
    })
      .then(() => {
        newsLetterEmailInput.value = "";
        submitBtn.style.background = submitColors.default;
        submitBtn.disabled = true
        const message = "You're in! we'll keep you in touch with us!";
        flashMessage.flash(true, message);
      })
      .catch((e) => {
        console.error(e);
        flashMessage.flash(false, e.message);
      })
      .finally(() => {
        grecaptcha.reset(captchaWidgetID);
        renderCapcthaMessage(capcthaContainer);
      });
  });
});

const faviconSelectors = [
  {
    name: "favicon-16x16.png",
    selector: $("link[rel='icon'][sizes='16x16']")[0] as HTMLLinkElement,
  },
  {
    name: "favicon-32x32.png",
    selector: $("link[rel='icon'][sizes='32x32']")[0] as HTMLLinkElement,
  },
  {
    name: "apple-touch-icon.png",
    selector: $("link[rel='apple-touch-icon']")[0] as HTMLLinkElement,
  },
  {
    name: "safari-pinned-tab.svg",
    selector: $("link[rel='mask-icon']")[0] as HTMLLinkElement,
  },
];

function toggleFaviconTheme(isDarkMode: boolean) {
  console.log(`FAVICON: ${isDarkMode ? "light" : "dark"}`);

  for (const f of faviconSelectors) {
    const [name, ext] = f.name.split(".");
    f.selector.setAttribute(
      "href",
      `/assets/images/favicons/${name}${isDarkMode ? "-light" : ""}.${ext}`
    );
  }
}

// favico switch
const prefersDarkTheme = window.matchMedia("(prefers-color-scheme: dark)");
toggleFaviconTheme(prefersDarkTheme.matches);

prefersDarkTheme.addEventListener("change", (e) =>
  toggleFaviconTheme(e.matches)
);
